import { forwardRef } from "react";

import { ITopMessage } from "~/types";

export interface TopBannerProps {
  topMessage: ITopMessage;
}

export type Ref = HTMLDivElement;

// eslint-disable-next-line react/display-name
const TopBanner = forwardRef<Ref, TopBannerProps>(({ topMessage }, ref) => {
  const isEmptyHtmlString =
    topMessage?.text?.replace(/<[^>]*>?/gm, "")?.trim?.().length === 0;
  if (!topMessage || !topMessage?.text || isEmptyHtmlString) return <></>;

  return (
    <div
      id="topBanner"
      ref={ref}
      style={{ backgroundColor: topMessage?.backgroundColor }}
    >
      <div className="container flex items-center justify-center gap-12 py-2 lg:gap-8 md:gap-6">
        <div
          dangerouslySetInnerHTML={{ __html: topMessage?.text }}
          className="ql-editor"
        />
      </div>
    </div>
  );
});

export default TopBanner;
