

export const DEFAULT_EXTRACT = ['script', 'noscript']

export function extractScripts(input: string, tags: string[] = DEFAULT_EXTRACT): { tag: string, attributes: Record<string, string | boolean>, content: string }[] {
    if (!input) return [];

    const tagPattern = tags.join('|');
    const regex = new RegExp(`<(${tagPattern})\\b([^>]*)>([\\s\\S]*?)<\\/\\1>`, 'gi');

    const result = [];
    let match;

    while ((match = regex.exec(input)) !== null) {
        const tag = match[1];
        const rawAttributes = match[2]?.trim();
        const content = match[3]?.trim();

        const attributes: Record<string, string | boolean> = {};
        rawAttributes.replace(/(\w+)(?:=["'](.*?)["'])?/g, (match, key, value) => {
            attributes[key] = value !== undefined ? value : true;
            return match;
        });

        result.push({ tag, attributes, content });
    }

    return result;
}
