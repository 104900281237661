import type {
  ActionFunctionArgs,
  LinksFunction,
  LoaderFunctionArgs,
  SerializeFrom,
} from "@remix-run/node";
import {
  isRouteErrorResponse,
  json,
  Links,
  Outlet,
  redirect,
  Scripts,
  ScrollRestoration,
  useNavigation,
  useRouteError,
  useRouteLoaderData,
} from "@remix-run/react";
// import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { getFormDataFromSearchParams, parseFormData } from "remix-hook-form";
import { useChangeLanguage } from "remix-i18next/react";
import { getToast, jsonWithSuccess } from "remix-toast";
import { Toaster } from "sonner";
import "swiper/css";
import "swiper/css/effect-fade";

import "node_modules/sonner/dist/styles.css";
import "react-quill/dist/quill.snow.css";

import Alert from "./components/atoms/Alert";
import ContentGuard from "./components/atoms/ContentGuard";
import ErrorComponent from "./components/organisms/ErrorComponent";
import FloatingCompareBar from "./components/organisms/FloatingCompareBar/FloatingCompareBar";
import Footer from "./components/organisms/Footer";
import Header from "./components/organisms/Header";
import HeaderMobile from "./components/organisms/HeaderMobile";
import LoadingOverlay from "./components/organisms/LoadingOverlay";
import TopBanner from "./components/organisms/TopBanner";
import SectionError from "./components/sections/SectionError";
import { ICategory } from "./entities/category";
import { ILocation } from "./entities/location";
import { ISalesTeamInfo } from "./entities/salesTeamInfo";
import { ISection } from "./entities/section";
import { TUnitType } from "./entities/unitType";
import { getEnv } from "./env.server";
import useFaviconLinks from "./hooks/use-favicon-links";
import { usePageChecker } from "./hooks/use-page-checker";
import usePreventActions from "./hooks/use-prevent-actions";
import useProjectRouteMatch from "./hooks/use-project-route-match";
import useSticky from "./hooks/use-sticky";
import {
  getCategory,
  getCommonAreaListing,
  getCommonPsfListing,
  // getCommonAreaListing,
  // getCommonPsfListing,
  getLocations,
  getPrices,
  getTOP,
  getUnitTypes,
} from "./services/commonService";
import { getProjectsByLocations, getSections } from "./services/projectService";
import { onSubmitUserPreference } from "./services/userPreference";
import useAppState from "./stores";
import { IDomain, IPaginateResponseData, IPaginationReq } from "./types";
import { IRouteLoaderData } from "./types/routeLoaderData";
import { MenuItems, MenuItemsMobile } from "./utilities/config/menuConfig";
import { DEFAULT_PROJECT_SG_TRACKING_ID_SCRIPTS } from "./utilities/config/scripts";
import { LIMIT, PAGE } from "./utilities/constants/common";
import { projectSections } from "./utilities/constants/projectSections";
import { EFormType } from "./utilities/enums/FormType";
import { Slug } from "./utilities/enums/Slug";
import { fetchDomainConfig } from "./utilities/helpers/fetchDomainConfig";
import { getClientInformation } from "./utilities/helpers/getClientInformation";
import { extractDomainFromRequest } from "./utilities/helpers/requestHelper";
import { extractScripts } from "./utilities/helpers/scripts";
import { formatCurrency } from "./utilities/helpers/string";
import { updateAnchorTags } from "./utilities/helpers/updateAnchorTags";

import { IMetadata } from "~/entities/metadata";
import * as i18n from "~/locales/i18n";
import i18nServer, { localeCookie } from "~/locales/i18n.server";
import stylesheet from "~/tailwind.css?url";
import { cn } from "~/utilities/cn";
import findFirstJpegSource from "~/utilities/helpers/findFirstImage";
import { generateColorVariables } from "~/utilities/helpers/generateColorVariables";
import { getLanguage } from "~/utilities/helpers/getLanguage";
export const links: LinksFunction = () => [
  { rel: "stylesheet", href: stylesheet },
];

export const handle = { i18n: ["translation"] };

// export const headers = ({ request, params }: LoaderFunctionArgs) => {
//   return {
//     "Document-Policy": "js-profiling", // This allows profiling
//   };
// };

export async function loader({ request, params }: LoaderFunctionArgs) {
  const requestUrl = new URL(request?.url);
  const siteUrl = `${requestUrl.protocol}//${requestUrl.host}`;
  const clientInformation = getClientInformation(request);
  const locale = getLanguage(params);
  const { toast, headers } = await getToast(request);
  const env = getEnv();
  const t = await i18nServer.getFixedT(request);

  const { config = null, redirectUrl } = await fetchDomainConfig(request, t);

  if (redirectUrl) {
    return redirect(redirectUrl, 301);
  }

  let domainConfig = {} as IDomain;

  if (isEmpty(config)) {
    console.error("config is empty");
    throw new Response(t("error.404.description"), {
      status: 404,
      statusText: "Bad Request",
    });
  }

  const landingPageProject = config?.project;
  const activeOption = config?.optionMappings?.find((item) => item?.active);
  const ldpHeaderScript = config?.useProjectSgTrackingId
    ? DEFAULT_PROJECT_SG_TRACKING_ID_SCRIPTS
    : activeOption?.landingPage?.headerScript || "";
  const ldpBodyScript = activeOption?.landingPage?.bodyScript || "";
  const ldpEnquiryScript = activeOption?.landingPage?.enquiryScript || "";

  const headerScript = landingPageProject
    ? ldpHeaderScript
    : config?.headerScript || "";

  const bodyScript = landingPageProject
    ? ldpBodyScript
    : config?.bodyScript || "";

  const enquiryScript = landingPageProject
    ? ldpEnquiryScript
    : config?.enquiryScript || "";

  let projectsByLocations = [] as ILocation[];
  let serializedLocaleCookie = "en";
  const projectMenuItems = [...projectSections];

  let resLocations = [] as ILocation[];
  let resUnitTypes = [] as TUnitType[];
  let resCategory = [] as ICategory[];
  let tops = [] as ITOP[];
  let prices = [] as number[];
  let areas = [] as number[];
  let psfs = [] as number[];
  let searchParams = {} as IPaginationReq;
  let sections = {} as IPaginateResponseData<ISection>;
  let domain = domainConfig?.name || "";

  if (config) {
    domainConfig =
      config?.domains?.find((d) => d.primary === true) || ({} as IDomain);
    domain = domainConfig?.name || "";

    projectsByLocations = await getProjectsByLocations(domainConfig?.name);
    serializedLocaleCookie = await localeCookie.serialize(locale);

    resLocations = await getLocations(domain);
    resUnitTypes = await getUnitTypes(domain);
    resCategory = await getCategory(domain);
    tops = await getTOP(domain);
    prices = await getPrices(domain);
    areas = await getCommonAreaListing(domain);
    psfs = await getCommonPsfListing(domain);
    searchParams = getFormDataFromSearchParams(request) as IPaginationReq;
    sections = await getSections({
      domain,
      pagination: {
        page: searchParams?.page || PAGE,
        limit: searchParams?.limit || LIMIT,
      },
    });
  }

  headers.append("Set-Cookie", serializedLocaleCookie);
  if (isEmpty(config?.user)) {
    throw new Response(t("error.404.description"), {
      status: 404,
      statusText: t("error.404.message"),
    });
  }

  const metadata: IMetadata = {
    title: config?.siteMetadata?.title ?? config?.siteTitle,
    description: config?.siteMetadata?.description ?? config?.seoDescription,
    ogImage:
      env.IMAGE_DOMAIN_URL +
      (isEmpty(config?.seoImage)
        ? findFirstJpegSource([config?.coverImage])?.urls[0]?.url
        : findFirstJpegSource([config?.seoImage])?.urls[0]?.url),
    twitterCreator:
      config?.siteMetadata?.twitterCreator ?? config?.twitterCreator,
    twitterSite: config?.siteMetadata?.twitterSite ?? config?.twitterSite,
  };

  const routeLoaderData: IRouteLoaderData = {
    siteUrl,
    protocol: requestUrl.protocol,
    config,
    domain,
    ENV: env,
    locale,
    metadata,
    colorScheme: config?.colorScheme,
    phoneNumber: config?.phoneNumber || "",
    logoHeader: config?.headerLogo,
    logoFooter: config?.footerLogo,
    siteLogo: config?.siteLogo,
    whatsapp: config?.whatsapp || "",
    siteDescription:
      typeof config?.siteDescription === "object"
        ? config?.siteDescription?.en
        : config?.siteDescription,
    socialLinks: config?.socialLinks || [],
    topMessage: config?.topMessage,
    userConfig: config?.user,
    topButton: config?.topButton,
    floatingButtonEnabled: config?.floatingButtonEnabled,
    salesTeamInfo: config?.salesTeamInfo || ({} as ISalesTeamInfo),
    siteDisclaimers: config?.siteDisclaimers,
    filterOptions: {
      locations: resLocations?.map((location) => ({
        id: location.slug,
        name: location.name,
      })),
      unitTypes: resUnitTypes?.map((unit) => ({
        id: unit.slug,
        bedRoomCount: `${unit?.bedRoomCount || "0"}`,
        isStudio: `${unit?.isStudio ? "true" : "false"}`,
        name: unit.title,
      })),
      categories: resCategory?.map((category) => ({
        id: category.slug,
        name: category.name,
      })),
      tops: tops?.map((top) => ({
        id: top?.value,
        name: top?.label,
      })),
      prices: prices?.map((price) => ({
        id: `${price}`,
        name: `${formatCurrency(price)}`,
      })),
      area: areas?.map((area) => ({
        id: `${area}`,
        name: `${area.toLocaleString()}`,
      })),
      psf: psfs?.map((psf) => ({
        id: `${psf}`,
        name: `${formatCurrency(psf)}`,
      })),
    },
    projectsByLocations,
    sections,
    projectMenuItems,
    toast,
    formNotice:
      updateAnchorTags(
        config?.siteDisclaimers?.formNotice,
        `https://${
          config?.originalDomain ? config?.originalDomain : domain
        }/privacy-policy`
      ) || "",
    gaTrackingId: config?.measuringId,
    landingPageProject,
    clientInformation,
    headerScript,
    bodyScript,
    enquiryScript,
  };

  return json(routeLoaderData, {
    headers,
  });
}

export async function action({ request }: ActionFunctionArgs) {
  try {
    const json = await request.clone().json();

    // submit greeting form
    if (json && json?.type === EFormType.GREETING_FORM) {
      const domain = extractDomainFromRequest(request);
      const response = await onSubmitUserPreference(json?.values, domain);
      if (response) {
        return jsonWithSuccess({ ok: true }, "You submitted successfully!");
      }
      return null;
    }
  } catch (e) {
    // Handle the error appropriately, such as logging or returning a specific response
  }
  const formData = await parseFormData<Record<string, string>>(request, false);
  const url = new URL(request?.url);

  Object.keys(formData)?.forEach((key) => {
    if (formData[key] && formData[key] !== "null") {
      return url.searchParams.set(key, formData[key]);
    }
    return url.searchParams.delete(key);
  });
  const params = url.searchParams.toString();
  return redirect(`${Slug.PROJECTS}?${params}`);
}

export function Layout({ children }: { children: React.ReactNode }) {
  // Prvent select & copy text actions on the page
  usePreventActions();
  const { headerHeight } = useAppState((state) => state);
  const loaderData = useRouteLoaderData("root") as IRouteLoaderData;

  const navigation = useNavigation();
  const error = useRouteError();

  const faviconLinks = useFaviconLinks(loaderData);
  const {
    config,
    colorScheme,
    landingPageProject,
    locale,
    headerScript,
    bodyScript,
    enquiryScript,
  } = { ...loaderData };
  const {
    isComparisonPage,
    isEnquiryReceivedPage,
    isHomePage,
    isProjectPage,
    isSectionsPage,
    isEmptyLayout,
  } = usePageChecker(landingPageProject);
  const { project } = useProjectRouteMatch();

  const { isSticky, bannerRef, headerRef } = useSticky();

  const headerScriptAdded = useRef(false); // Tracks whether headerScript is appended
  const enquiryScriptAdded = useRef(false); // Tracks whether enquiryScript is appended
  const bodyScriptAdded = useRef(false); // Tracks whether headerScript is appended

  // useEffect(() => {
  //   if (!headerScriptAdded.current && config?.headerScript) {
  //     const headElement = document.head;
  //     const tempContainer = document.createElement("div");
  //     tempContainer.innerHTML = config.headerScript;

  //     // Append each child of the temp container to the head
  //     Array.from(tempContainer.children).forEach((child) => {
  //       headElement.appendChild(child);
  //     });

  //     // Mark as added
  //     headerScriptAdded.current = true;

  //     // Clean up logic if needed (not necessary if only running once)
  //     return () => {
  //       Array.from(tempContainer.children).forEach((child) => {
  //         headElement.removeChild(child);
  //       });
  //     };
  //   }
  // }, [config?.headerScript]);

  // useEffect(() => {
  //   if (
  //     !enquiryScriptAdded.current &&
  //     config?.enquiryScript &&
  //     isEnquiryReceivedPage
  //   ) {
  //     const headElement = document.head;
  //     const tempContainer = document.createElement("div");
  //     tempContainer.innerHTML = config.enquiryScript;

  //     // Append each child of the temp container to the head
  //     Array.from(tempContainer.children).forEach((child) => {
  //       headElement.appendChild(child);
  //     });

  //     // Mark as added
  //     enquiryScriptAdded.current = true;

  //     // Clean up logic if needed (not necessary if only running once)
  //     return () => {
  //       Array.from(tempContainer.children).forEach((child) => {
  //         headElement.removeChild(child);
  //       });
  //     };
  //   }
  // }, [config?.enquiryScript, isEnquiryReceivedPage]);

  useEffect(() => {
    if (!bodyScriptAdded.current && bodyScript) {
      const bodyElement = document.body;
      const tempContainer = document.createElement("div");
      tempContainer.innerHTML = bodyScript;

      // Append each child of the temp container to the head
      Array.from(tempContainer.children).forEach((child) => {
        bodyElement.appendChild(child);
      });

      // Mark as added
      bodyScriptAdded.current = true;

      // Clean up logic if needed (not necessary if only running once)
      return () => {
        Array.from(tempContainer.children).forEach((child) => {
          bodyElement.removeChild(child);
        });
      };
    }
  }, [bodyScript]);

  if (
    isRouteErrorResponse(error) &&
    navigation &&
    navigation.state !== "loading"
  ) {
    return <ErrorComponent error={error}>{children}</ErrorComponent>;
  }

  const headerScriptsExtract = extractScripts(headerScript || "");
  const enquiryScriptExtract = extractScripts(enquiryScript || "");

  return (
    <html lang={locale ?? i18n.fallbackLng}>
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        {faviconLinks}
        {/* <Meta /> */}
        <Links />
        <style
          dangerouslySetInnerHTML={{
            __html: `:root { ${generateColorVariables(colorScheme)} }`,
          }}
        />
        {/* Render the inline script in the head */}

        {Array.isArray(headerScriptsExtract) &&
          headerScriptsExtract.length > 0 &&
          headerScriptsExtract.map((script, idx) => (
            <script
              key={idx}
              dangerouslySetInnerHTML={{
                __html: script.content,
              }}
              {...script.attributes}
            />
          ))}

        {isEnquiryReceivedPage &&
          Array.isArray(enquiryScriptExtract) &&
          enquiryScriptExtract.length > 0 &&
          enquiryScriptExtract.map((script, idx) => (
            <script
              key={idx}
              dangerouslySetInnerHTML={{
                __html: script.content,
              }}
              {...script.attributes}
            />
          ))}

        <title>{config?.siteTitle}</title>
      </head>
      <body
        className={cn(
          `flex min-h-screen flex-col bg-background`,
          (isProjectPage || isSectionsPage) && "bg-backgroundPageProject"
        )}
        suppressHydrationWarning={true}
      >
        <Toaster position="top-left" className="toaster group z-[1000000]" />

        {/* Modal */}
        <div id="modal-root" className="z-[110]" />
        {/* Loading overlay */}
        <LoadingOverlay isLoading={navigation.state === "loading"} />

        {!isSectionsPage &&
          !isEnquiryReceivedPage &&
          !loaderData?.landingPageProject &&
          !isEmptyLayout && (
            <>
              <TopBanner ref={bannerRef} topMessage={loaderData?.topMessage} />
              <Header
                ref={headerRef}
                logo={loaderData?.logoHeader}
                menus={MenuItems}
                topButton={loaderData?.topButton}
                areColorsSame={true}
              />
            </>
          )}
        {!isSectionsPage &&
          !isEnquiryReceivedPage &&
          !loaderData?.landingPageProject &&
          !isEmptyLayout && (
            <HeaderMobile
              ref={headerRef}
              logo={loaderData?.logoHeader}
              menus={MenuItemsMobile}
              projectMenuItems={loaderData?.projectMenuItems}
              phoneNumber={loaderData?.phoneNumber || ""}
              socialLinks={loaderData?.socialLinks || []}
              topButton={loaderData?.topButton}
              top={headerHeight}
              isSticky={isSticky}
              project={project}
              areColorsSame={true}
            />
          )}
        <div
          className={cn(
            "flex-1 pt-4 [&:has(.remove-padding)]:pt-0",
            (isHomePage || isProjectPage) && "lg:pt-0",
            isHomePage && loaderData?.landingPageProject && "pt-0",
            isSectionsPage &&
              "flex items-center justify-center *:w-full [&_section]:w-full [&_section]:max-w-[1280px]"
          )}
        >
          {/* {children} */}
          <ContentGuard>{children}</ContentGuard>
        </div>

        {!isSectionsPage &&
          !isEnquiryReceivedPage &&
          !loaderData?.landingPageProject &&
          !isEmptyLayout &&
          navigation &&
          navigation.state !== "loading" && (
            <Footer
              logo={loaderData?.logoFooter}
              phoneNumber={loaderData?.phoneNumber || ""}
              socialLinks={loaderData?.socialLinks || []}
              siteTitle={config?.siteTitle || ""}
              siteDescription={
                (loaderData?.siteDescription as unknown as string) || ""
              }
              disclaimer={loaderData?.siteDisclaimers?.footerDisclaimer}
              domain={loaderData?.domain || ""}
              locations={loaderData?.projectsByLocations}
              wrapperClass={
                landingPageProject
                  ? "ldp lg:gap-x-4 md:gap-x-4 sm:px-4"
                  : "container"
              }
            />
          )}
        <ScrollRestoration />
        {!isComparisonPage && navigation && navigation.state !== "loading" && (
          <FloatingCompareBar
            classNameContainer="sticky bottom-0 left-0 z-50"
            locale={locale ?? i18n.fallbackLng}
          />
        )}
        {/* Render the inline script in the body */}
        <Scripts />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(loaderData?.ENV)};`,
          }}
        />
      </body>
    </html>
  );
}

function App() {
  const { locale, toast } = useRouteLoaderData("root") as IRouteLoaderData;
  const { alertMessage, setAlert } = useAppState((state) => state);
  const { t } = useTranslation();

  useChangeLanguage(locale);

  return (
    <>
      <Outlet />

      <Alert
        onClose={() => {
          setAlert(false, "");
        }}
        title={t(toast?.message || "") || alertMessage}
        variants="success"
      />
    </>
  );
}

// export default withSentry(App);
export default App;

export function ErrorBoundary() {
  const error = useRouteError();

  const getErrorCode = () => {
    if (isRouteErrorResponse(error)) {
      return "404";
    } else if (error instanceof Error) {
      return "500";
    }
    return "500";
  };

  // captureRemixErrorBoundaryError(error);

  return <SectionError errorCode={getErrorCode()} error={error} />;
}

export type TLoaderData = SerializeFrom<typeof loader>;
