import { useLocation } from "@remix-run/react";
import { useEffect } from "react";

import { Slug } from "~/utilities/enums/Slug";

const allowPath = [Slug.LDP_EDITOR];
const usePreventActions = () => {
  const pathname = useLocation().pathname;

  useEffect(() => {
    if (allowPath.includes(pathname as Slug)) {
      return;
    }

    const handleContextMenu = (event: MouseEvent) => event.preventDefault();
    const handleCopy = (event: ClipboardEvent) => event.preventDefault();
    const handlePaste = (event: ClipboardEvent) => event.preventDefault();
    const handleCut = (event: ClipboardEvent) => event.preventDefault();
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && (event.key === "p" || event.key === "s")) {
        event.preventDefault();
      }
    };

    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("copy", handleCopy);
    document.addEventListener("paste", handlePaste);
    document.addEventListener("cut", handleCut);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("copy", handleCopy);
      document.removeEventListener("paste", handlePaste);
      document.removeEventListener("cut", handleCut);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [pathname]);
};

export default usePreventActions;
